
import styled from "styled-components";
// import lock from './assets/manta/lock.png'
import Axelar from './assets/blast/Axelar.png';
import { useWindowSize } from "react-use";
import bg from './assets/blast/bg.png';
import btn from './assets/blast/btn.svg';
import arrow from './assets/blast/arrow.svg';

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background:#000e17;
overflow:hiddren ;
background-image:url(${bg}) ;
background-size:cover;
background-position:50% ;
background-repeat: no-repeat;

@media screen and (max-width:1000px){
  background-position:left;
}
 `
 

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Desc=styled.div`
width:570px ;
margin-top:36px ;
text-align:left ;
color: #FCFDCB;
font-size:50px ;
font-family: GeomGraphicW01-Regular;
line-height: 75px;
z-index:400;

i{
  font-style:normal ;
}

aside{
  padding-right:76px ;
}
/* @media screen and (max-height:770px) and (min-width:1002px) {
  padding-top:202px ;
}
@media screen and (max-height:670px) and (min-width:1002px){
  padding-top:152px ;
} */
@media screen and (max-width:1500px){
  width:495px ;
  font-size:46px ;
  line-height: 70px;
}

@media screen and (max-width:1280px){
 padding-left:117px ;
 box-sizing:content-box ;
}

@media screen and (max-width:950px){
  font-size:40px;
  line-height:60px;
  width:fit-content ;
  padding-top:0px ;
  padding-left:0 ;
}

@media screen and (max-width:750px){
  width:fit-content ;
/* width:325px; */
  font-size:30px ;
  line-height:50px;
  padding-left:0;
  padding-top:0;
} 
`

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'GeomGraphicW01-Regular';
font-weight: normal;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;
color:#fff;


&>img:first-child{
  width:30px ;
  height:auto ;
}


&>mark::before{
  content: "&";
  font-family: 'GeomGraphicW01-Regular';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img.lock{
      width:25px ;
      height:25px !important ;
    }
}

 `
const Inner=styled.div`
width:1280px ;
margin:0 auto ;
position:relative ;


@media screen and (max-width:1280px){
   width:100% ;
}

@media screen and (max-width:950px){
   main{
    width:100% ;
    padding-left:0 ;
    display:flex ;
    justify-content:center ;
    flex-direction:column ;
    align-items:center ;
    padding-bottom:100px ;
   }
}

 `
const AXELAR=styled.div` 
display: inline-block;
display: flex;
padding-top: 0px;
margin-left: 10px;


.axelar{
  width:auto;
  height: 18px;
  vertical-align: bottom;
  
}

`
 const Inner2=styled(Inner)`
 position:absolute ;
 top:0 ;
 width:100%;
 height:100%;
 z-index:200;

 @media screen and (max-width:1500px){
    left:80px ;
  }

  @media screen and (max-width:1280px){
   left:0 ;
  }
  @media screen and (max-width:750px){
   main{
    height:85vh;
    display:flex;
    align-items:center ;
   }
  }
 `
const BTNBox=styled.div` 

width:354px ;
height:52px;
position:absolute ;
top:-5px;
left:-10px;

@media screen and (max-width:750px){
  width:335px;
  height:46px;

  left:-25px;
  top:-8px;
}



/* border-radius: 2.059px; */
/* border: 2.745px solid #FFE562; */
/* background: #1C1804; */

`

const BTN = styled.img` 
width:354px ;
height:52px;
text-shadow: 0px 0px 12.2px rgba(252, 252, 73, 0.96);
margin-left:-5px ;

@media screen and (max-width:750px){
  width:335px;
  height:46px;
  margin-left:0px ;
}
`
const TEXT = styled.div` 
position:absolute ;
left:0 ;
top:0;
bottom:0;
z-index:10;
color: #FFE560;
font-family: 'GeomGraphicW01-Regular';
font-size: 16px;

display:flex ;
align-items:center ;
padding-left:20px ;
cursor: pointer;

span{
  animation-name:TEXTShadow;
  animation-duration:2.5s;
  animation-iteration-count: infinite ;
  /* text-shadow: 0px 0px 12.2px rgba(252, 252, 73, 0.96); */
}

@keyframes TEXTShadow {
  0%{
    text-shadow:0px 0px 12.2px rgba(252, 252, 73, 0.06);
  }
  50%{
    text-shadow: 0px 0px 12.2px rgba(252, 252, 73, 0.96);
  }
  100%{
    text-shadow:0px 0px 12.2px rgba(252, 252, 73, 0.06);
  }

}
@media screen and (max-width:750px){
  font-size: 14px;
  padding-left:35px ;
}
`
const SHADOW = styled.div` 
position:relative ;

width: 328px;
height: 42px;
flex-shrink: 0;
border-radius: 1.721px;



&::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background:#FFE562;
            /* filter: blur(13px);
            -webkit-filter: blur(13px); */

              animation-name:Shadow;
  animation-duration:2.5s;
  animation-iteration-count: infinite ;
        }

/* border: 2.295px solid #FFE562;  */
/* 
box-shadow: 0px 0px 11px 0px #FFE562 ; */
/* 





  /* animation-name:Shadow;
  animation-duration:2.5s;
  animation-iteration-count: infinite ; */


@keyframes Shadow {
  0%{
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  50%{
    filter: blur(13px);
    -webkit-filter: blur(13px);
  }

  100%{
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
}


@media screen and (max-width:1280px){
 margin-left:117px ;
 box-sizing:content-box ;
}

@media screen and (max-width:950px){
 margin-left:0px ;
}

@media screen and (max-width:750px){
  width:290px;
  height:30px;
}
`
const ARROW = styled.img` 
width: 9px;
height: 15px;
margin-left:14px;




`

const SHADOWBox = styled.div` 
width:100%;
margin-left:5px ;
margin-top:212px ;
cursor: pointer;



@media screen and (max-width:950px){
 width:420px ;
 margin-left:10px ;
}

@media screen and (max-width:750px){
 width:315px ;
 margin-top:0px ;
}
`
// Naming Service 
// on Mantle For Wallets, 
// Websites, and more

export function Linea(props){
  const {onEnterNextPage}=props;
  const {width}=useWindowSize()

  /**
   * Naming Service on 
Manta For Wallets, 
Websites, and more
   */
  // Naming Service on Blast for Wallets, Websites, 
//and more
    return (
      <Container style={{ height: width < 768 ? window.innerHeight : "100vh" }}>
        <Inner>
          <Inner2>
            <main>
              <SHADOWBox onClick={(e)=>{
                  e.stopPropagation();
                  window.open(`https://app.blastns.org/account/registrant#invite`,'_blank')
              }}>
                <SHADOW>
                  <BTNBox>
                    <BTN src={btn} />
                    <TEXT>
                      <span>Earn More with Referral on Blast</span>
                      <ARROW src={arrow} />
                    </TEXT>
                  </BTNBox>
                </SHADOW>
              </SHADOWBox>

              <Desc>
                Naming Service on <br />
                <section style={{ display: "flex", alignItems: "center" }}>
                  {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}>naming for </mark>
                                wallets */}
                  Blast for Wallets,
                </section>
                Websites, and more
                {/* <aside style={{width:"100%",textAlign:"right",}}>websites</aside>
                        <aside style={{width:"100%",textAlign:"right",color:"#737373"}}>on Linea</aside> */}
              </Desc>
            </main>
          </Inner2>
        </Inner>
        <Data>
          {/* <LogoCard /> */}
          <Footer onClick={(e) => onEnterNextPage(e)}>
            {/* Omni-chain Solution Powered  */}
            Powered by
            {/* <img className="lock" src={lock} alt="" /> */}
            <AXELAR>
            <img src={Axelar}  alt="" className="axelar" />
            </AXELAR>
           
            {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
          </Footer>
        </Data>
      </Container>
    );
}




