import { forwardRef } from 'react';
import styled from 'styled-components';
import close from '../../assets/blast/close.svg';
import warning from '../../assets/blast/warning.png';

const Modal = styled.dialog`
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  .notification {
    position: relative;
    width: 760px;
    max-width: 100%;
    padding: 30px 40px;
    border-radius: 16px;
    border: 1px solid #fcfdcb;
    background: #2b2d26;
    font-family: GeomGraphicW01-Regular;
    color: #9ea888;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    .em {
      color: #fcfdcb;
    }

    .close {
      position: absolute;
      right: 40px;

      @media screen and (max-width: 768px) {
        width: 30px;
      }
    }

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-top: 48px;
      text-align: center;
      font-size: 32px;
      line-height: 32px; /* 100% */
      color: #fcfdcb;

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .tip {
      margin-top: 30px;
    }

    .guide {
      margin-top: 35px;
    }

    ul {
      margin-top: 35px;

      li {
        line-height: 24px;
        list-style: disc;
      }
    }

    button {
      padding: 8px 0;
      outline: none;
      background-color: transparent;
      border: 1.756px solid #fcfdcb;
      color: #fcfdcb;
      border-radius: 32px;
      font-size: 18px;
      font-family: GeomGraphicW01-Regular;
      line-height: 1;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .btns {
      margin: 40px auto 0;
      width: 360px;
      max-width: 100%;

      .top {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 100%;

        button {
          flex: 1;
        }
      }

      .bottom {
        margin-top: 20px;
        padding: 10px 0;
        width: 100%;
        color: #000;
        background-color: #fcfdcb;
      }
    }
  }
`;

const Notification = forwardRef((props, ref) => {
  function onClose() {
    ref.current.close();
  }

  return (
    <Modal
      onClick={e => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      ref={ref}
    >
      <div className="notification">
        <img className="close" src={close} alt="" onClick={onClose} />

        <h4>
          <img src={warning} width={30} alt="" /> Notification
        </h4>

        <p className="tip">
          Star Protocol has rebranded as <span className="em">XSTAR</span> and blastns.org will no longer be used.
        </p>

        <p className="guide em">What do you need to do now?</p>

        <ul>
          <li>Upgrade your existing .blast domains for points, soon to be tokens.</li>
          <li>Buy .blast domains at XName (powered by XSTAR) via "Visit XName."</li>
          <li>Withdraw pending commissions through the "Withdraw" button.</li>
        </ul>

        <div className="btns">
          <div className="top">
            <button onClick={() => window.open('https://app.blastns.org/account/registrant#invite', '_blank')}>
              Withdraw
            </button>
            <button onClick={() => window.open('https://blast.xname.app', '_blank')}>Visit XName</button>
          </div>

          <button onClick={() => window.open('https://xname.app/migrate', '_blank')} className="bottom">
            Update Your Domain
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default Notification;
