import styled from "styled-components";
// import arrow from "../../assets/linea/arrow.png";
import { Footer2 } from "../Footer2/footer";
import { Footer3 } from "../Footer3/footer";
import { partaner } from "./data";
import stratified from "../../assets/partners2/stratified.png";
import mexc from "../../assets/partners2/6.png";
import you from "../../assets/partners2/youbicapital.png";
import luci from "../../assets/partners2/3.png";
import lancer from "../../assets/partners2/2.png";
import ac from "../../assets/partners2/5.png";

import f3 from "../../assets/partners2/f3.png";
import okx from "../../assets/partners2/okx.png";
import fotor from "../../assets/partners2/fotor.png";
import republic from "../../assets/partners2/republic.png";

import bip32 from "../../assets/partners/bip32.png";
import kryptos from "../../assets/partners/kryptos.png";
import { useWindowSize } from "react-use";
import cn from 'classnames';
import styles from './index.module.css';
import { useReducer } from "react";

const section2 = [
  {
    name: f3,
    height: 50,
    marginRight: 112,
    height2: 40,
    marginRight2: 97,
    height3: 39,
    marginRight3: 66,
    height4: 18,
    marginRight4: 31,
  },
  {
    name: okx,
    height: 42,
    marginRight: 78,
    height2: 33,
    marginRight2: 56,
    height3: 32,
    marginRight3: 65,
    height4: 15,
    marginRight4: 30,
  },
  {
    name: fotor,
    height: 41,
    marginRight: 50,
    height2: 30,
    marginRight2: 40,
    height3: 35,
    marginRight3: 65,
    height4: 17,
    marginRight4: 29,
  },
  {
    name: republic,
    height: 47,
    marginRight: 0,
    height2: 37,
    marginRight2: 0,
    height3: 35,
    marginRight3: 0,
    height4: 17,
    marginRight4: 0,
  },
];

const sectionList = [
  {
    name: kryptos,
    height: 56,
    height2: 45,
    height3: 34,
    marginRight3: 37,
    marginRight: 111,
    marginRight2: 89,
    height4: 21,
    marginRight4: 32,
  },
  {
    name: luci,
    height: 56,
    height2: 45,
    height3: 34,
    marginRight3: 44,
    marginRight: 111,
    marginRight2: 89,
    height4: 21,
    marginRight4: 32,
  },
  {
    name: mexc,
    height: 50,
    marginRight: 77,
    height2: 40,
    marginRight2: 61,
    height3: 32,
    marginRight3: 45,
    height4: 19,
    marginRight4: 32,
  },
  {
    name: you,
    height: 47,
    marginRight: 33,
    height2: 37,
    marginRight2: 26,
    height3: 31,
    marginRight3: 43,
    height4: 19,
    marginRight4: 0,
  },

  {
    name: stratified,
    height: 48,
    marginRight: 22,
    height2: 38,
    marginRight2: 17,
    height3: 29,
    marginRight3: 44,
    height4: 17,
    marginRight4: 24,
  },
  {
    name: lancer,
    height: 45,
    marginRight: 40,
    height2: 36,
    marginRight2: 32,
    height3: 27,
    marginRight3: 45,
    height4: 15,
    marginRight4: 26,
  },
  {
    name: ac,
    height: 42,
    height2: 33,
    marginRight: 0,
    marginRight2: 0,
    height3: 27,
    marginRight3: 46,
    height4: 15,
    marginRight4: 25,
  },
  {
    name: bip32,
    height: 42,
    height2: 33,
    marginRight: 0,
    marginRight2: 0,
    height3: 27,
    marginRight3: 0,
    height4: 15,
  },
];
const LineaCard = styled.div`
  width: 100%;
  padding-top: 20px;
  /* height:calc(100vh - 80px);
  overflow-y:auto ; */
  background-color: #000;
  /* overflow-x:hidden ;  */

  header {
    margin-top: 48px;
    margin-bottom:15px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 30px;
      font-family: 'GeomGraphicW01-Bold';
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      /* margin-bottom: 13px; */
    }

    h2 {
      white-space: pre-wrap;
      font-size: 14px;
      font-family: 'GeomGraphicW01-Regular';
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
      opacity: 0.9;
    }

   
  }
  @media screen and (max-width: 1280px) {
    /* padding-top:47px ; */
    h1 {
      font-size: 21px !important;
      margin-bottom: 13px !important;
    }
  }
  @media screen and (max-width: 750px) {
    padding-top: 35px;
    /* h1{  
        font-size: 23px !important;
        margin-bottom:6px !important;
    }
    h2{
        font-size: 13px !important;
    } */
  }

  @media screen and (max-width: 550px) {
    padding-top: 35px;
    h1 {
      font-size: 18px !important;
      margin-bottom: 3px !important;
    }
    h2 {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 378px) {
    h1 {
      white-space:nowrap ;
      font-size: 15px !important;
      margin-bottom: 3px !important;
    }
    h2 {
      font-size: 12px !important;
    }
  }

  main {
    margin: 0 auto;
    padding: 0 140px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    margin-bottom: 50px;
    gap: 38px 20px;
    box-sizing: border-box;

    @media screen and (max-width: 1198px) {
      width: 890px;
      padding: 0;
      padding-top: 50px;
    }
    @media screen and (max-width: 1180px) {
      gap: 30px 16px;
      margin-bottom: 39px;
    }

    @media screen and (max-width: 890px) {
      width: 700px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;

const Inner = styled.div`
  width: 1280px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Button = styled.button`
  margin: 0 auto;
  width: 514px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #fff;
  background:transparent ;
  color:#fff ;


  font-size: 22px;
  font-family: 'GeomGraphicW01-Regular';
  margin-bottom: 96px;
  cursor: pointer;

  &:hover{
    background: #FCFC49;
    color:#000;
  }

  @media screen and (max-width: 1180px) {
    width: 402px;
    height: 39px;
    border-radius: 23px;
    font-size: 16px;
    margin-bottom: 75px;
  }
  @media screen and (max-width: 550px) {
    width: 310px;
    height: 39px;
    border-radius: 23px;
    font-size: 14px;
    margin-bottom: 35px;
  }
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  /* margin-top:79px ; */
  font-family: 'GeomGraphicW01-Bold';
  color: #fff;
  position: relative;
  white-space: pre-wrap;
  & > div {
    width: 100%;
    position: absolute;
    display: inline-block;
    top: 0;
    height: 9px;
    background-color: palegreen;
  }

  @media screen and (max-width: 1280px) {
    font-size: 21px;
  }
  @media screen and (max-width: 750px) {
    font-size: 19px;
  }
`;

const FirstColumn = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1280px) {
    justify-content: center;
  }
`;
const Section = styled.div`
  margin-top: 50px;
  display: grid;
  align-items: center;
  padding-left: 112px;
  /* flex-wrap:wrap ; */
  grid-template-columns: auto auto auto auto auto auto auto auto;

  @media screen and (max-width: 1280px) {
    padding-left: 0px;
  }

  @media screen and (max-width: 1055px) {
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;

    margin-top: 39px;
    & > img:first-child,
    img:nth-child(2),
    img:nth-child(3) {
      margin-bottom: 15px;
    }
  }
  @media screen and (max-height: 790px) {
    margin-top: 52px;
  }
  @media screen and (max-width: 450px) {
    padding: 0px;
    margin-top: 39px;
  }
`;

const SectionImg = styled.img`
  width: auto;
  height: ${(props) => props.height};
  margin-right: ${(props) => props.margin};
`;

const Text = styled.div`
  margin-top: 54px;
  margin-bottom: 48px;
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;

  font-family: 'GeomGraphicW01-Regular';
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;

  position: relative;

  & > div {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: palegreen;
  }

  @media screen and (max-width: 750px) {
    font-size: 12px !important;
    color: #fff;
    margin-top: 39px;
    margin-bottom: 30px;
    font-weight: 500;
  }
`;
const Section2 = styled.div`
  display: grid;
  align-items: center;
  padding-left: 280px;
  grid-template-columns: auto auto auto auto auto;

  @media screen and (max-width: 1280px) {
    padding: 0 154px 0 187px;
  }

  @media screen and (max-width: 450px) {
    padding: 0px;
  }
`;

const Divider = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0.5;
`;
// const Container=styled.div`
// height: 100vh;
// width:100% ;
// overflow:auto ;
// `
const Butn=styled.div` 
`

const BtnContainer=styled.div`
width:100% ;
display:flex ;
justify-content:center ;
`
// const APIButton=styled.div` 
// width:100% ;
// display:flex ;
// justify-content:center ;
// margin:15px ;

// a{
//   display:block ;
//   text-decoration:none ;
//   padding:0 15px ;
//   height:40px ;
//   display:flex ;
//   align-items:center ;
//   justify-content:center ;
//   border:1px solid #fff ;
//   color:#fff ;
//   border-radius:20px ;
//   overflow:hidden ;
//   font-size:16px ;
//   font-family: 'Helvetica-Regular';
// }

// a:hover{
//   background:linear-gradient(90deg, rgba(229, 83, 93, 1), rgba(234, 234, 33, 1), rgba(80, 209, 239, 1), rgba(51, 204, 102, 1));
//   color:#000;
// }
// `
// const card2=[1,6,11,16,21,26,31,36,41,46,51,56,61,66,71,76,81,86,91,96,101,106];
const btns=[
    { id: 1, name: 'ALL', active: true },
    { id: 2, name: 'Wallet', active: false },
    { id: 3, name: 'Social', active: false },
    { id: 4, name: 'Game', active: false },
    { id: 5, name: 'NFT', active: false },
    { id: 6, name: 'Defi', active: false },
    { id: 7, name: 'Bridge', active: false },
    { id: 8, name: 'Infrastructure', active: false },
    { id: 9, name: 'Tool', active: false },
    { id: 10, name: 'DAO', active: false }
  ]
export function Cards(props) {
    const {page}=props;
  const { width } = useWindowSize();
  console.log(width,'width');
 
  const [btnss,dispatch]=useReducer(reducer,btns);
  const [logos,LogoDispatch]=useReducer(reducer,JSON.parse(JSON.stringify(partaner)));

  function reducer(state,action){
    if(action.type === "select"){
        const arr=JSON.parse(JSON.stringify(state));
        arr.forEach((v,index)=>{
            if(v.id===action.payload){
                v.active=true;
            }else{
                v.active=false;
            }
        })
        return arr;
    }

    if(action.type==='logo'){
        if(action.payload.trim().toLocaleLowerCase()==="all"){
            return partaner;
        }
        return partaner.filter((v,index)=>{
            if(v.category.trim().toLocaleLowerCase()===action.payload.trim().toLocaleLowerCase()){
                return true;
            }
            return false;
        })
    }
  }

  function onSelected(id,name){
    dispatch({type: 'select', payload:id});
    onChooseSameType(name);
  }

  function onChooseSameType(type){
    LogoDispatch({type: 'logo', payload:type})
  }
  return (
    <LineaCard id="partners" className={styled.lineaPage}>
      <Inner>
        <Header>
          {/* <div></div> */}
          {`INSTITUTIONAL   INVESTORS`}
        </Header>
        <FirstColumn>
          <Section>
            {sectionList.map((v, index) => {
              if (width > 750) {
                return (
                  <SectionImg
                    src={v.name}
                    height={`${v.height3}px`}
                    margin={`${v.marginRight3}px`}
                    key={index}
                  />
                );
              } else {
                return (
                  <SectionImg
                    src={v.name}
                    height={`${v.height4}px`}
                    margin={`${v.marginRight4}px`}
                    key={index}
                  />
                );
              }
            })}
          </Section>
        </FirstColumn>

        {/* 不要修改Text,不要动，不要动 */}
        <Text>
          {/* <div></div> */}
          {width>850 && `&  individual  investors  who  are  the  early  supporters  of  
renowned  Web3  companies  and  crypto  VCs
`.toLocaleUpperCase()}
 {width<=850 && `&  individual  investors  who  are  the  early  
supporters  of  renowned  Web3  companies  
and  crypto  VCs
`.toLocaleUpperCase()}
        </Text>
        <FirstColumn>
          <Section2>
            {section2.map((v, index) => {
              if (width > 750) {
                // return <SectionImg src={v.name}
                // height={width > 1280 ? `${v.height}px` : `${v.height2}px`}
                // margin={width > 1280 ? `${v.marginRight}px` : `${v.marginRight2}px`}
                // key={index} />
                return (
                  <SectionImg
                    src={v.name}
                    height={`${v.height3}px`}
                    margin={`${v.marginRight3}px`}
                    key={index}
                  />
                );
              } else {
                return (
                  <SectionImg
                    src={v.name}
                    height={`${v.height4}px`}
                    margin={`${v.marginRight4}px`}
                    key={index}
                  />
                );
              }
            })}
          </Section2>
        </FirstColumn>
        <Divider />
      </Inner>
      <div className={styled.pages2} style={{ height: window.innerHeight,background:'#000', position: "relative",overflow:page===3?'auto':"hidden",paddingRight:(width>750 &&page===3)?'0px':'4px'}} id="list">
        <Inner>
          <header>
            <h1>Explore the Wonder of Star Ecosystem</h1>
            {/* ALL Wallet Social Game NFT Defi Bridge Infrastructure Tool DAO  */}
          </header>
          {/* <APIButton>
            <a href={"https://docs.star.co/manta-integration"}>
            Integrate Domain API Now
            </a>
          </APIButton> */}
          <BtnContainer>
            <Butn className={styles.btns}>
                    {
                        btnss.map((v,index)=><button 
                            key={index} 
                            className={cn({[styles.active]:v.active})}
                            onClick={()=>onSelected(v.id,v.name)}
                            >
                                {v.name}
                            </button>
                        )
                    }
            </Butn>
          </BtnContainer>
          <main>
            {logos.map((v, index) => {
            //   if (card2.includes(index)) {
            //     return <Card2 key={index} />;
            //   }
              return (
                <Card
                  href={v.projecturl}
                  key={index}
                  img={v.logourl}
                  projectname={v.projectname}
                  category={v.category}
                />
              );
            })}
          </main>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <a href="https://docs.google.com/forms/d/1mmPeD4C580LE2dkXj90AGADUjtBX1pF3KZZYsEgU6UE" target="_blank" rel="noreferrer">
               <Button>Submit Your Projects</Button>
            </a>
          </div>
        </Inner>
        {width>890 && logos.length<=5 ? <Footer3/>: <Footer2 logosNumber={logos.length} />}
      </div>
    </LineaCard>
  );
}
const CardItem = styled.div`
  padding-top: 26px;
  box-sizing: border-box;
  width: 180px;
  height: 260px;
  background: #1d1d1d;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 1px solid #4b4b4b;
  position: relative;
  article {
    margin-top: 20px;
    font-size: 20px;
    font-family: 'GeomGraphicW01-Regular';
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  aside {
    font-size: 16px;
    font-family: 'GeomGraphicW01-Regular';
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }
  @media screen and (max-width: 1180px) {
    padding-top: 20px;
    width: 141px;
    height: 203px;
    article {
      margin-top: 16px;
      font-size: 15px;
    }
    aside {
      font-size: 13px;
    }
  }
`;


const RoundSide = styled.div`
  margin: 0 auto;
  width: 136px;
  height: 136px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 106px;
    height: 106px;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: 110px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }
`;
const RoundInner = styled.div`
  width: 110px;
  height: 110px;
  background: #000000;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }

  &:hover{
    animation: jelly 0.5s;
  }
  @keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
`;

const Round = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background: #4b4b4b;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  @media screen and (max-width: 1180px) {
    width: 13px;
    height: 13px;
    right: -6px;
    top: -6px;
  }
`;
function Card(props) {
  const { img, projectname, category,href} = props;
  return (
    <a href={href} target="_blank" rel="noreferrer">
     <CardItem>
      <Round></Round>
      <RoundSide>
        <RoundInner>
          <Logo src={img} />
        </RoundInner>
      </RoundSide>
      <article>{projectname}</article>
      <aside>{category}</aside>
    </CardItem>
    </a>
  );
}

/********************************************************** */

// const CardItem2 = styled.div`
//   padding-top: 26px;
//   box-sizing: border-box;
//   width: 180px;
//   height: 260px;
//   background: #3b3852;
//   box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 1);
//   border-radius: 10px;
//   position: relative;

//   display: flex;
//   justify-content: center;

//   p {
//     display: block;
//     font-size: 17px;
//     font-family: Galyon-Regular, Galyon;
//     font-weight: 400;
//     color: #ffffff;
//     line-height: 30px;
//   }

//   img {
//     position: absolute;
//     width: 19px;
//     height: 19px;
//     bottom: 15px;
//     right: 15px;
//   }

//   @media screen and (max-width: 1180px) {
//     width: 141px;
//     height: 203px;
//     padding-top: 20px;

//     p {
//       font-size: 14px;
//       line-height: 23px;
//     }
//     img {
//       width: 15px;
//       height: 15px;
//     }
//   }
// `;
// function Card2() {
//   return (
//     <CardItem2>
//       <p>
//         Get the best of <br />
//         Ethereum for up <br />
//         to 100X <br />
//         lower fees
//       </p>
//       <img src={arrow} alt="" />
//     </CardItem2>
//   );
// }
