import styled from "styled-components"



const Wrapper=styled.div` 
fill: #FCFC49;
filter: drop-shadow(0px 0px 25px #FCFC49);
`


const Btn=styled.button` 
width:171px ;
height:48px ;
/* 菱形 */
clip-path: polygon(20px 0,100% 0,100% 50%,calc(100% - 20px) 100%,0 100%,0 50%);

background-color:#FCFC49 ;

font-family: GeomGraphicW01-Bold;
font-size: 16px;

border:none;
cursor:pointer;
color:#000;


&:hover{
    background: #fff;
}

@media screen and (max-width:1000px){
    width: 134px;
    height: 38px;
    font-size: 13px;
}
`
export function Button(props){
    return (
        <Wrapper>
            <Btn>{props.children}</Btn>
        </Wrapper>
    )
}